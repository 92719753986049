<template>
  <div class="resultContainer">
    <div class="fix-order">
      <img
        src="https://qiniu.zhiyunys.com/img/order-check.png"
        @click="ToOrder()"
      />
    </div>
    <div class="resultBox">
      <div class="fix_newFriend flex flexwrap alic justs">
        <div class="flex flexwrap alic juststart">
          <div class="headImg">
            <img src="https://qiniu.zhiyunys.com/yanpitiao/sacc/result/icon.png" />
          </div>
          <div class="middle_txt">
            <p class="txt1">国学文化</p>
            <p class="txt2">请添加大师微信免费咨询</p>
          </div>
        </div>
        <div class="add_btn" @click="show = true">请教添加</div>
      </div>
      <div class="result_img">
        <img mode="widthFix" src="https://qiniu.zhiyunys.com/yanpitiao/sacc/result/fxbg.png" />
      </div>
      <div class="normal_img">
        <img mode="widthFix" src="https://qiniu.zhiyunys.com/yanpitiao/sacc/result/tc_1.png" />
      </div>
      <div class="result_img mb10">
        <img mode="widthFix" src="https://qiniu.zhiyunys.com/yanpitiao/sacc/result/fxjg.png" />
      </div>
      <div class="reprotBox">
        <div class="titleImg">
          <img mode="widthFix" src="https://qiniu.zhiyunys.com/yanpitiao/sacc/result/mpfx.png" />
        </div>
        <div class="lycSrco">
          <div v-for="(item, index) in resultList1" :key="index">
            <div class="">
              <div class="reprotBox_title title1">
                {{ index + 1 }}、{{ item.title }}
              </div>
              <div class="reprotBox_des">{{ item.explain }}</div>
            </div>
            <div v-if="index == 1" class="wx_box mb5">
              <div class="des">
                今年你的感情运势如何? 是否会遇到良缘和变数?
                婚姻会长久吗，何时容易被出轨?
                添加老师微信免费获取更多情感密码详情。
              </div>
              <div class="chat_num mt5">微信号:gxjdwh888(长按复制)</div>
            </div>
            <div v-if="index == 3" class="wx_box mb5">
              <div class="des">
                为何工作总是不顺? 未来几年会有发财的机会吗?
                何时要当心破财、失败、犯小人?
                现在添加微信，0元为你揭晓近期事业财富运程
              </div>
              <div class="chat_num mt5">微信号:gxjdwh888(长按复制)</div>
            </div>
          </div>
          <div class="wx_box mb5">
            <div class="des">
              除此之外，你还要注意哪些健康问题?
              近期是否容易有伤灾意外?老年身体又有什么隐患?
              添加微信领取专属的养生建议。
            </div>
            <div class="chat_num mt5">微信号:gxjdwh888(长按复制)</div>
          </div>
          <div>
            <div class="result_img">
              <img mode="widthFix" src="https://qiniu.zhiyunys.com/yanpitiao/sacc/result/ypt.png" />
            </div>
            <div v-for="(item, index) in resultList2" :key="index">
              <div class="">
                <div v-if="item.title" class="reprotBox_title title2">
                  {{ item.title }}
                </div>
                <div class="reprotBox_des">{{ item.explain }}</div>
              </div>
            </div>
            <div v-for="(item, index) in timeResult" :key="index">
              <div class="">
                <div v-if="item.title" class="time_title">
                    <img src="https://qiniu.zhiyunys.com/yanpitiao/sacc/result/g_1.png"/>
                  {{ item.title }}
                </div>
                <div class="reprotBox_des">{{ item.explain }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="suggest mt10">
        <p class="title">逢凶化吉（大师提点）</p>
        <p class="des">
          根据您的命盘信息，大师分析，您在事业上容易导致
          缺乏贵人帮助，凡是只能靠自己，还总被小人算计。
          钱财左手右手出，很难存下来。比较难找到称心如意
          的伴侣，就是找到了也是在激情过后，和伴侣的情感
          生活会常常感到无趣！您的健康也会出现一定程度小
          病，如果不及时注重，可能会引起更加大的麻烦。
        </p>
        <img mode="widthFix" src="https://qiniu.zhiyunys.com/yanpitiao/sacc/result/yszd.png" />
      </div>
      <div class="flex flex_col flex_col_center">
        <div v-if="scrollTop > 60" class="fixedBox" @click="show = true">
          <div>添加师父微信，获取更多分析详情</div>
        </div>
      </div>
    </div>
    <!-- 加好友弹窗 -->
    <van-popup v-model="show" :closeable="true" :round="true">
      <div class="popup_box">
        <div class="popup_bg">
          <img mode="widthFix" src="https://qiniu.zhiyunys.com/yanpitiao/sacc/result/p_1.png" />
        </div>
        <div class="qr_box">
          <img mode="widthFix" src="https://qiniu.zhiyunys.com/yanpitiao/sacc/result/wx.png" />
          <p class="code_txt">微信号:gxjdwh888(长按复制)</p>
        </div>
      </div>
    </van-popup>
  </div>
</template>
  
<script>
import api from "@/apis/index";
export default {
  name: "",
  data() {
    return {
      userInfo: {},
      resultList1: [],
      resultList2: [],
      timeResult: [],
      odd: this.$route.query.odd,
      scrollTop: 0,
      triggered: false,
      show: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", () => {
      this.scrollTop = document.documentElement.scrollTop;
    });
    this.getData();
  },
  methods: {
    async getData() {
      let data = {
        out_trade_no: this.odd,
      };
      try {
        const res = await api.getContent(data);
        this.userInfo = res.userInfo;
        this.resultList1 = res.resultInfo1;
        this.resultList2 = res.resultInfo2;
        this.timeResult = res.timeResult;
      } catch (error) {}
    },
    ToOrder() {
      // this.$Router.replaceAll({path: "/pages/index/index" })
      this.$router.push("/order");
    },
    toHelp() {
      this.$router.push("/Help");
    },
    onRefresh() {
      //做一个判断，判断triggered 是否为true
      if (!this.triggered) {
        this.triggered = true;
        setTimeOut((e) => {
          this.triggered = false;
        }, 1500);
      }
    },
  },
};
</script>
<style  lang="scss" scoped>
.resultContainer {
  background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/result/red_bg.png") no-repeat;
  background-size: 100% 100%;
  // height: 100vh;

  .resultBox {
    padding: 100px 31px 80px;
    .normal_img {
      text-align: center;
      height: 30px;
      img {
        width: 100%;
      }
    }
    .result_img {
      //   padding: 0 0.3rem;
      text-align: center;
      img {
        width: 75%;
      }
    }

    .infoBox {
      background: url("https://qiniu.zhiyunys.com/hmcs/static/my_bg.png")
        no-repeat;
      background-size: 100% 100%;
      padding: 45px;

      .txt {
        font-size: 18px;
        font-weight: 500;
        color: #054636;
        margin-bottom: 9px;
      }
    }
  }
  .fix_newFriend {
    position: fixed;
    top: 20px;
    width: 7.5rem;
    // left: 20px;
    // width: 90%;
    // margin: 20px auto;
    padding: 10px 15px;
    background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/result/btn_1.png") no-repeat;
    background-size: 100% 100%;
    z-index: 999;
    .headImg {
      width: 50px;
      height: 50px;
      background: #ffffff;
      border-radius: 50%;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .middle_txt {
      color: #800909;
      .txt1 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
      }
      .txt2 {
        font-size: 12px;
        font-weight: 400;
      }
    }
    .add_btn {
      font-size: 13px;
      font-weight: normal;
      color: #ffed51;
      //   width: 64px;
      padding: 11px;
      background: #b11d1d;
      border-radius: 18px;
      text-align: center;
      cursor: pointer;
    }
  }
}
.reprotBox {
  background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/result/bg_1.png") no-repeat;
  background-size: 100% 100%;
  height: 500px;
  padding: 45px 25px;
  margin-top: 25px;
  position: relative;
  .lycSrco {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .titleImg {
    width: 220px;
    text-align: center;
    display: flex;
    flex: 1;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translate(-50%, 0);

    img {
      width: 100%;
    }
  }
  .title1 {
    background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/result/btn_3.png") no-repeat;
    background-size: 100% 100%;
    width: 65%;
  }
  .title2 {
    background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/result/btn_4.png") no-repeat;
    background-size: 100% 100%;
    // width: 75%;
  }

  &_title {
    text-align: center;
    height: 50px;
    line-height: 45px;
    // padding: 15px 0;
    font-size: 16px;
    font-weight: 500;
    color: #fcebbd;
    margin: 0 auto;
  }
  &_des {
    font-size: 14px;
    font-weight: 400;
    color: #573115;
    margin: 10px 0;
    line-height: 20px;
  }
  .time_title {
    font-size: 14px;
    font-weight: bold;
    color: #573115;
    img{
        width: 12px;
        height: 12px;
    }
    ::before{
        background-image: url('https://qiniu.zhiyunys.com/yanpitiao/sacc/result/g_1.png');
    }
  }
  .wx_box {
    background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/result/btn_2.png") no-repeat;
    background-size: 100% 100%;
    text-align: center;
    padding: 15px;
    .des {
      font-size: 13px;
      font-weight: normal;
      color: #b11518;
      line-height: 22px;
    }
    .chat_num {
      font-size: 14px;
      font-weight: bold;
      color: #a9171a;
    }
  }
}
.suggest {
  background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/result/bg_2.png") no-repeat;
  background-size: 100% 100%;
  padding: 30px 20px 20px;
  .title {
    font-size: 18px;
    font-weight: bold;
    color: #ffed51;
    padding-left: 30px;
  }
  .des {
    font-size: 14px;
    font-weight: 400;
    color: #f5dbaa;
    margin: 15px 0;
    line-height: 17px;
  }
  img {
    width: 100%;
  }
}
.popup_box {
  background-color: #800809;
  padding: 30px 20px;
  width: 7rem;
  .popup_bg {
    width: 90%;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  .qr_box {
    width: 250px;
    height: 220px;
    background-color: #fff;
    text-align: center;
    margin: 0 auto;
    padding: 15px 0;
    img {
      width: 200px;
      height: 200px;
    }
    .code_txt {
      font-size: 14px;
      font-weight: bold;
      color: #a9171a;
    }
  }
}
.fixedBox {
  background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/result/cs_title.png") no-repeat;
  background-size: 100% 100%;
  position: fixed;
  bottom: 15px;
  width: 300px;
  margin: 20px auto;
  padding: 15px 0;
  border-radius: 10px;
  text-align: center;
  font-size: 16px;
  color: #ffed51;
  font-weight: bold;
  -webkit-animation-name: scaleDraw;
  /*关键帧名称*/
  -webkit-animation-timing-function: ease-in-out;
  /*动画的速度曲线*/
  -webkit-animation-iteration-count: infinite;
  /*动画播放的次数*/
  -webkit-animation-duration: 2s;
  /*动画所花费的时间*/
}

@keyframes scaleDraw {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.1);
  }
}
</style>