<template>
  <div class="formContainer">
    <div class="formBox">
      <div class="title_bg">
        <img src="https://qiniu.zhiyunys.com/yanpitiao/sacc/index/xx.png" />
      </div>
      <div class="row1">
        <img mode="widthFix" src="https://qiniu.zhiyunys.com/yanpitiao/sacc/index/srkwa.png" />
      </div>
      <div class="van-formBox">
        <van-form @submit="onSubmit" class="mt5">
          <p class="inputTitle">左右眼</p>
          <van-field readonly clickable name="picker" :value="form.eyelid" label="" placeholder="请选择"
            @click="showType = true" />
          <p class="inputTitle">您的姓名</p>
            <van-field v-model="form.userName" name="您的姓名" label="" placeholder="您的姓名" />
          <p class="inputTitle">您的生日</p>
          <van-field readonly clickable name="datetimePicker" :value="form.usergBirthTime" label="" placeholder="请选择"
            @click="showPopup = true" />
        </van-form>
      </div>

      <div class="submit-box">
        <div class="xieyiBox flex justc alic mt10">
          <img src="https://qiniu.zhiyunys.com/yanpitiao/sacc/index/icon_notSelect.png" class="_checked" mode=""
            v-if="!agree" @click="checkedBox" />
          <img src="https://qiniu.zhiyunys.com/yanpitiao/sacc/index/icon_seleted.png" class="_checked" mode="" v-else
            @click="checkedBox" />
          <div class="checkbox-text">
            已阅读并同意<span class="xieyi" @click.stop="navToPlatform('/protocol')">《用户协议》</span>
          </div>
        </div>
        <div class="submitBox row2 mt10" @click="toSubmit">
          <div class="imgbtn">立即测算</div>
        </div>
      </div>
    </div>
    <!-- <div class="inputBottom">
        <img class="img2" src="https://qiniu.zhiyunys.com/hmcs/static/index/input2_bg.png" mode="widthFix" />
      </div> -->
    <!-- 测算类型 -->
    <van-popup v-model="showType" position="bottom">
      <van-picker show-toolbar :columns="typeList" @confirm="onConfirm" @cancel="showType = false" :default-index="0" />
    </van-popup>
    <!-- 日期选择弹窗 -->
    <div class="calendar" v-if="showPopup">
      <van-datetime-picker class="picker" v-model="currentDate" type="date" :columns-order="['year', 'month', 'day']"
        :formatter="formatter" :min-date="minDate" :max-date="maxDate">
        <slot name="default">
          <div class="switch-btn">
            <span :class="`${isLunar ? '' : 'active'}`" @click="changeFormatter()">公历</span>
            <span :class="`${isLunar ? 'active' : ''}`" @click="changeFormatter()">农历</span>
          </div>
        </slot>
      </van-datetime-picker>
      <div class="option-btn">
        <span @click="showPopup = false">取消</span>
        <span @click="confirmDateTime">确定</span>
      </div>
    </div>
  </div>
</template>
  
<script>
import api from "@/apis/index";
import calendar from "@/utils/calendar";
export default {
  name: "customForm",
  props: {},
  computed: {
    isObjectEmpty() {
      for (let key in this.form) {
        if (!this.form[key]) {
          return true;
        }
      }
      return false;
    },
  },
  data() {
    return {
      agree: true,
      form: {
        // orderType: "左眼",
        eyelid:"左眼",
        userName: "",
        // numberName: "111",
        usergBirthTime: "",
        channel: this.$route.query.apkChannel || "noChannel",
      },
      typeList: [
        "左眼",
        "右眼",
      ],
      showPopup: false,
      showType: false,
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(2200, 12, 12),
      currentDate: new Date(1995, 6, 15),
      minDate: new Date(1940, 1, 1),
      maxDate: new Date(),
      isLunar: false,
      formatter: (type, val) => {
        if (type === "hour") {
          return val + "时";
        }
        return val;
      },
      isChild: false,
    };
  },
  components: {},
  methods: {
    navToPlatform(url) {
      this.$router.push(url);
    },
    async toSubmit() {
      if (this.isObjectEmpty) return this.$toast("请填写完信息再提交哦~", 3000);
      if (!this.agree) return this.$toast("请勾选用户协议", 3000);
      if (parseInt(this.isChild) < 18) return this.$toast("未满18岁，暂不提供服务！");
      const inner = ["根", "苗", "花", "果"];
      const outer = [
        "壬",
        "葵",
        "甲",
        "乙",
        "丙",
        "丁",
        "戊",
        "己",
        "庚",
        "辛",
      ];
      const center = [
        "子",
        "未",
        "申",
        "酉",
        "戊",
        "亥",
        "子",
        "丑",
        "寅",
        "卯",
        "辰",
        "巳",
      ];
      this.form.horoscope = `${inner[Math.floor(Math.random() * 3)]}、${center[Math.floor(Math.random() * 9)]
        }、${outer[Math.floor(Math.random() * 10)]}`;
      this.form.isCallBack = this.$route.query.isCallBack || 0;
      localStorage.setItem("userInfo", JSON.stringify(this.form));
      try {
        const res = await api.createOrder(this.form);
        if (res.code != 0) return this.$toast(res.message);
        this.$router.push({
          path: "/loadingResult",
          query: {
            odd: res.data.odd,
          },
        });
        localStorage.setItem("odd", res.data.odd);
        localStorage.setItem("price", res.data.price);
      } catch (error) {
        console.log("error", error);
      }
    },
    checkedBox() {
      this.agree = !this.agree;
      if (!this.agree) {
        this.agree = 0;
      } else {
        this.agree = 1;
      }
    },
    onConfirm(value) {
      this.form.eyelid = value;
      this.showType = false;
    },
    confirmDateTime() {
      this.form.usergBirthTime = this.currentDate.Format("yyyy年MM月dd日");
      this.showPopup = false;
      const year = this.currentDate.getFullYear();
      const month = this.currentDate.getMonth() + 1;
      const date = this.currentDate.getDate();
      const hour = this.currentDate.getHours();
      this.showDate = `${this.isLunar ? "农(阴)历 " : "公(阳)历 "}`;
      const curDate = new Date();
      this.isChild = this.getAge(
        `${year}-${month}-${date}`,
        `${curDate.getFullYear()}-${curDate.getMonth() + 1
        }-${curDate.getDate()}`
      );
      if (this.isLunar) {
        const obj = calendar.lunar2solar(
          year,
          month,
          date,
          calendar.leapMonth(year) === month ? true : false
        );
        this.usergBirthTime = `公(阳)历 ${obj.cYear}年${obj.cMonth}月${obj.cDay
          }日 ${hour}:00-${hour}:59(${calendar.getGzHour(hour)})时`;
        this.usernBirthTime = `农(阴)历 ${year}年${month}月${date}日 ${hour}:00-${hour}:59(${calendar.getGzHour(
          hour
        )})时`;
        this.showDate = this.usernBirthTime;
        this.normalDate = `农(阴)历 ${year}年${month}月${date}日 ${calendar.getGzHour(
          hour
        )}时`;
      } else {
        const obj = calendar.solar2lunar(year, month, date);
        this.usernBirthTime = `农(阴)历 ${obj.lYear}年${obj.lMonth}月${obj.lDay
          }日 ${hour}:00-${hour}:59(${calendar.getGzHour(hour)})时`;
        this.usergBirthTime = `公(阳)历 ${year}年${month}月${date}日 ${hour}:00-${hour}:59(${calendar.getGzHour(
          hour
        )})时`;
        this.showDate = this.usergBirthTime;
        this.normalDate = `公(阳)历 ${year}年${month}月${date}日 ${calendar.getGzHour(
          hour
        )}时`;
      }
    },
    cancelDateTime() {
      this.showPopup = false;
    },
    onSubmit(values) {
      console.log("submit", values);
    },
    changeFormatter() {
      this.isLunar = !this.isLunar;
      this.formatter = (type, val) => {
        if (type === "hour") {
          return val + "时";
        }
        if (this.isLunar) {
          if (type === "year") {
            return val;
          }
          if (type === "month") {
            const temp = {
              "01": "正",
              "02": "二",
              "03": "三",
              "04": "四",
              "05": "五",
              "06": "六",
              "07": "七",
              "08": "八",
              "09": "九",
              10: "十",
              11: "冬",
              12: "腊",
            };
            return temp[val] + "月";
          }
          if (type === "day") {
            const temp = {
              "01": "初一",
              "02": "初二",
              "03": "初三",
              "04": "初四",
              "05": "初五",
              "06": "初六",
              "07": "初七",
              "08": "初八",
              "09": "初九",
              10: "初十",
              11: "十一",
              12: "十二",
              13: "十三",
              14: "十四",
              15: "十五",
              16: "十六",
              17: "十七",
              18: "十八",
              19: "十九",
              20: "二十",
              21: "甘一",
              22: "甘二",
              23: "甘三",
              24: "甘四",
              25: "甘五",
              26: "甘六",
              27: "甘七",
              28: "甘八",
              29: "甘九",
              30: "三十",
              31: "三一",
            };
            return temp[val];
          }
        }
        return val;
      };
    },
    getAge(birthday, lastDay) {
      // 先截取到字符串中的年、月、日
      let selectYear = birthday.split("-")[0];
      let selectMonth = birthday.split("-")[1];
      let selectDay = birthday.split("-")[2];
      // 得到当前时间的年、月、日
      let cal = lastDay === "" ? new Date() : new Date(lastDay);
      let yearNow = cal.getFullYear();
      let monthNow = cal.getMonth() + 1;
      let dayNow = cal.getDate();

      // 用当前年月日减去生日年月日
      let yearMinus = yearNow - selectYear;
      let monthMinus = monthNow - selectMonth;
      let dayMinus = dayNow - selectDay;

      let age = "";
      if (yearMinus < 0) {
        return "生日不可小于当前时间";
      } else {
        if (yearMinus === 0) {
          if (monthMinus < 0) {
            return "生日不可小于当前时间";
          } else {
            if (monthMinus > 0) {
              if (dayMinus >= 0) {
                return (
                  monthMinus + "个月" + (dayMinus > 0 ? dayMinus + "天" : "")
                );
              }
              return (
                (monthMinus - 1 === 0 ? "" : monthMinus - 1 + "个月") +
                (dayNow + (this.getDate(birthday) - selectDay)) +
                "天"
              );
            } else {
              if (dayMinus < 0) {
                return "生日不可小于当前时间";
              }
              return dayMinus + "天";
            }
          }
        } else {
          age = yearMinus + "岁";
          if (monthMinus === 0) {
            if (dayMinus >= 0) {
              return age + (dayMinus === 0 ? "" : dayMinus + "天");
            }
            return (yearMinus - 1 === 0 ? "" : yearMinus - 1 + "岁") + "11个月";
          } else if (monthMinus > 0) {
            age +=
              dayMinus >= 0
                ? monthMinus + "个月"
                : monthMinus - 1 > 0
                  ? monthMinus + "个月"
                  : this.getDate(birthday) - selectDay + dayNow + "天";
            return age;
          } else {
            return (
              (yearMinus - 1 === 0 ? "" : yearMinus - 1 + "岁") +
              (12 - selectMonth + monthNow) +
              "个月"
            );
          }
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.formContainer {
  position: relative;

  .formBox {
    margin: 30px 14px 0;
    // height: 340px;
    background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/index/form_bg.png") no-repeat;
    background-size: 100% 100%;
    padding: 25px;
    // z-index: 7999;
    position: relative;
    .title_bg{
      width: 150px;
      height: 50px;
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translate(-50%,0);
      img{
        width: 100%;
        height: 100%;
      }
    }

    .van-formBox {
      background-color: transparent;
      .inputTitle{
        font-size: 14px;
        font-weight: 500;
        color: #FFFFFF;
        margin: 10px 0;
      }
    }

    .row1 {
      margin: 20px;

      img {
        width: 100%;
      }
    }

    .row2 {
      text-align: center;

      img {
        width: 80%;
      }
    }

    .submit-box {
      .xieyiBox {
        img {
          width: 15px;
          height: 15px;
        }
      }

      .checkbox-text {
        font-size: 15px;
        color: #FFF;
        margin-left: 5px;

        .xieyi {
          font-weight: 400;
          color: #ECC896;
        }
      }
    }
  }

  .inputBottom {
    img {
      height: 140px;
      width: 100%;
    }

    .img1 {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
    }

    .img2 {
      position: absolute;
      bottom: -125px;
      left: 0;
    }
  }
}
/deep/.van-cell::after{
  border-bottom: none;
}
/deep/.van-cell {
  // background-color:transparent !important;
}

@keyframes scaleDraw {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.1);
  }
}

.submitBox {
  -webkit-animation-name: scaleDraw;
  /*关键帧名称*/
  -webkit-animation-timing-function: ease-in-out;
  /*动画的速度曲线*/
  -webkit-animation-iteration-count: infinite;
  /*动画播放的次数*/
  -webkit-animation-duration: 2s;
  /*动画所花费的时间*/
  .imgbtn{
    background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/index/btn.png") no-repeat;
    background-size: 100% 100%;
    width: 250px;
    height: 46px;
    font-size: 20px;
    font-weight: 500;
    color: #A2181B;
    line-height: 46px;
    margin: 0 auto;
  }
}

.calendar {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999;

  .picker {
    width: 100%;
    position: absolute;
    bottom: 60px;
    left: 0;
    background-color: #fff;
    //   background: url("https://qiniu.zhiyunys.com/img/calendar-bg.png")
    //     no-repeat;
    //   background-size: 100% 100%;
  }

  .switch-btn {
    margin: 0 auto;
    font-size: 22px;

    span {
      display: inline-block;
      width: 140px;
      height: 44px;
      line-height: 44px;
      text-align: center;
      border-radius: 8px 0 0 8px;
      color: #A2181B;
      border: 1px solid #A2181B;

      &:last-child {
        border-radius: 0 8px 8px 0;
        //   border-left: none;
      }

      &.active {
        background: #A2181B;
        color: #fff;
      }
    }
  }

  .option-btn {
    width: calc(100% - 40px);
    position: absolute;
    bottom: 0;
    left: 0;
    background: #fff;
    z-index: 999;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 20px;

    span {
      display: inline-block;
      width: 150px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      border: 1px solid #A2181B;
      color: #FFF;
      border-radius: 10px;
      background-color: #A2181B;

      &:first-child {
        //   background: url("https://qiniu.zhiyunys.com/img/cancel.png") no-repeat;
        //   background-size: 100% 100%;
        //   color: #666;
      }

      &:last-child {
        //   background: url("https://qiniu.zhiyunys.com/img/confirm.png")
        //     no-repeat;
        //   background-size: 100% 100%;
        //   color: #fff;
      }
    }
  }
}

/deep/.van-field__label {
  ont-size: 16px;
  color: rgb(12, 58, 82);
  font-weight: 600;
  width: 66px;
}

/deep/.van-cell__value {
  background: #fff;
  padding: 5px 10px !important;
  border: 1px solid #ffc16e !important;
}

/deep/.van-cell {
  background-color: transparent;
  padding: 5px 0;
  align-items: center;
  align-content: center;
  -webkit-align-items: center;
  -webkit-align-content: center;
}

/deep/.van-picker__toolbar {
  padding-top: 20px;
  padding-bottom: 5px;
}

/deep/.van-picker-column__item--selected {
  background-color: #fadbc8;
  color: #000;
}
</style>