<template>
  <div class="content">
    <van-notice-bar
      left-icon="volume-o"
      text="我平台所有测试仅供娱乐，不得作为生活、工作之指导。我平台产品拒绝向未成年人提供服务，如未成年人使用切造成一切后果由其监护人自行承担。本网页数据安全由阿里云提供，严格按照法律法规和用户协议对您的信息进行全方位的保护，请放心使用！"
    />
    <div class="tips" v-if="showTips">
      <div class="order-desc">您有进行中的测算订单喔</div>
      <div style="display: flex; align-items: center">
        <div class="go-order" @click="jumpToOrder">立即查看</div>
        <div class="close-tips" @click="showTips = false">关闭</div>
      </div>
    </div>
    <div class="banner"></div>
    <div class="posiBox">
      <div class="rowImg" style="text-align: center">
        <img mode="widthFix" src="https://qiniu.zhiyunys.com/yanpitiao/sacc/index/banner_title.png" />
      </div>
      <custom-form />
      <div class="rowImg mt10" style="text-align: center">
        <img mode="widthFix" src="https://qiniu.zhiyunys.com/yanpitiao/sacc/index/lsly.png" />
      </div>
      <div class="rowImg" style="text-align: center">
        <img
          class="tip"
          mode="widthFix"
          src="https://qiniu.zhiyunys.com/yanpitiao/sacc/index/xj_title.png"
        />
      </div>
      <div class="rowImg" style="text-align: center">
        <van-swipe
          :autoplay="4000"
          style="height: 10.4rem"
          indicator-color="#FFDEB0"
        >
          <van-swipe-item v-for="(item, index) in images" :key="index">
            <img mode="widthFix" :src="item.img" />
            <!-- <img mode="widthFix" src="https://qiniu.zhiyunys.com/yanpitiao/sacc/index/swipe1.png" /> -->
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="box_bg1 boxItem">
        <div class="title">
          <img mode="widthFix" src="https://qiniu.zhiyunys.com/yanpitiao/sacc/index/sm.png" />
        </div>
        <img
          class="mt5"
          mode="widthFix"
          src="https://qiniu.zhiyunys.com/yanpitiao/sacc/index/title2.png"
        />
        <img
          class="mt5"
          mode="widthFix"
          src="https://qiniu.zhiyunys.com/yanpitiao/sacc/index/title3.png"
        />
        <img
          class="mt5"
          mode="widthFix"
          src="https://qiniu.zhiyunys.com/yanpitiao/sacc/index/title1.png"
        />
      </div>
      <div class="box_bg2 boxItem">
        <div class="title">
          <img mode="widthFix" src="https://qiniu.zhiyunys.com/yanpitiao/sacc/index/fn.png" />
        </div>
        <img
          class="mt5"
          mode="widthFix"
          src="https://qiniu.zhiyunys.com/yanpitiao/sacc/index/fn_title.png"
        />
      </div>
      <div class="rowImg">
        <img mode="widthFix" class="" src="https://qiniu.zhiyunys.com/yanpitiao/sacc/index/shzn.png" />
      </div>
      <div class="rowImg mt10">
        <img mode="widthFix" class="" src="https://qiniu.zhiyunys.com/yanpitiao/sacc/index/nm.png" />
      </div>
      <div class="rowImg">
        <img mode="widthFix" class="" src="https://qiniu.zhiyunys.com/yanpitiao/sacc/index/sfk.png" />
      </div>
      <div class="box_bg2 boxItem">
        <div class="title">
          <img mode="widthFix" src="https://qiniu.zhiyunys.com/yanpitiao/sacc/index/jh.png" />
        </div>
        <img
          class="mt5"
          mode="widthFix"
          src="https://qiniu.zhiyunys.com/yanpitiao/sacc/index/title4.png"
        />
        <img
          class="mt5"
          mode="widthFix"
          src="https://qiniu.zhiyunys.com/yanpitiao/sacc/index/wd5.png"
        />
      </div>
      <div class="rowImg" style="text-align: center">
        <img
          class="tip"
          style="margin-top: 0"
          mode="widthFix"
          src="https://qiniu.zhiyunys.com/yanpitiao/sacc/index/pj.png"
        />
      </div>
      <div class="commentBg mt10">
        <div class="mydt-notice" id="mydtContent">
          <div class="mydt-content">
            <div
              class="commentBg_item mydt-content-item"
              v-for="(item, index) in commentList"
              :key="index"
            >
              <div class="flex flexrow juststart">
                <div class="row_1">
                  <span class="">{{ item.username }}</span>
                  <span class="ml5">{{ item.phone }}</span>
                </div>
                <div class="row_2">
                  <span>{{ item.time }}</span>
                </div>
              </div>
              <div class="row_3 hidetwo">
                <span>{{ item.txt }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb20"></div>
      <custom-form ref="uForm" />
      <div class="AQText">版权:合肥对对对网络信息咨询有限公司江西分公司</div>
    </div>
    <div class="flex flex_col flex_col_center">
      <div v-show="isScrollInRange" class="fixedBox" @click="ToScroll">
        <p class="send">眼跳立即分析预测-超准</p>
        <!-- <img mode="widthFix" src="https://qiniu.zhiyunys.com/yanpitiao/sacc/pay/title_bg.png" /> -->
      </div>
    </div>
  </div>
</template>
  
<script>
import customForm from "@/components/form/index.vue";
import api from "@/apis/index.js";
export default {
  data() {
    return {
      width: 0,
      show: false,
      commentList: [
        {
          username: "张先生",
          phone: "132****4511",
          time: "2022/10/18",
          txt: "抱着试一试的心态测了下，感觉还挺准的！",
        },
        {
          username: "陈先生",
          phone: "188****8787",
          time: "2022/11/25",
          txt: "最近眼皮老是跳，也不知道是好是坏，多亏了师傅的详细解答，希望对以后有所帮助",
        },
        {
          username: "刘女士",
          phone: "182****3232",
          time: "2023/02/25",
          txt: "右眼一直跳来跳去，很影响心情，测了下，感觉是这么回事儿。",
        },
        {
          username: "梁先生",
          phone: "137****0300",
          time: "2023/02/20",
          txt: "不知道啥情况，左眼老跳，哈哈，会是有好事情发生吗，朋友介绍测了下真的挺准",
        },
        {
          username: "白女士",
          phone: "188****2665",
          time: "2023/01/23",
          txt: "帮老公测了下，还挺准的，加了师傅的微信详细沟通了，希望真的可以解决就好了",
        },
        {
          username: "洪先生",
          phone: "134****9989",
          time: "2023/02/09",
          txt: "平时工作不顺心，在这里测了一下，说是什么最近缺点那啥忘了，后面通过加大师微信才慢慢有些好",
        },
        {
          username: "陆女士",
          phone: "184****3223",
          time: "2023/02/13",
          txt: "最近老是不顺心感觉，眼睛一直跳，心慌的很，抱着试一试的心态测了下，真的不错",
        },
        {
          username: "黄女士",
          phone: "178****2344",
          time: "2023/02/20",
          txt: "朋友说这里可以测试眼皮跳，没多想，嘿嘿，试一试呗，师傅解答挺详细的。",
        },
      ],
      noticeList: [
        "我平台所有测试仅供娱乐，不得作为生活、工作之指导。我平台产品拒绝向未成年人提供服务，如未成年人使用切造成一切后果由其监护人自行承担。本网页数据安全由阿里云提供，严格按照法律法规和用户协议对您的信息进行全方位的保护，请放心使用！",
      ],
      showTips: false,
      scrollViewHeight: 300,
      images: [
        { img: require("/static/sacc/index/swipe1.png") },
        { img: require("/static/sacc/index/swipe2.png") },
        { img: require("/static/sacc/index/swipe3.png") },
      ],
      scrollTop: 0,
    };
  },
  computed: {
    isScrollInRange() {
      return this.scrollTop > 1000 && this.scrollTop < 4000;
    },
  },
  mounted() {
    function f() {
      requestAnimationFrame(f);
    }
    requestAnimationFrame(f);
    this.scrollingDynamics();
    window.addEventListener("scroll", () => {
      this.scrollTop = document.documentElement.scrollTop;
    });
    api.getList().then((res) => {
      console.log(res);
      if(res.length>0){
          if (res.find((item) => item.status === 0)) {
            this.showTips = true;
          }
      }
    });
  },
  components: { customForm },
  methods: {
    close() {
      this.show = false;
      // console.log('close');
    },
    jumpToOrder() {
      this.$router.push("/order");
    },
    scrollingDynamics() {
      this.$nextTick(() => {
        const showArea = document.querySelector(".mydt-notice");
        showArea.scrollTop = 0;
        function f() {
          let hhh =
            showArea.scrollHeight -
            document.getElementById("mydtContent").clientHeight;
          if (showArea.scrollTop >= hhh) {
            showArea.scrollTop = 0;
          } else {
            const speed = 1;
            showArea.scrollTop += speed;
          }
          requestAnimationFrame(f);
        }
        requestAnimationFrame(f);
      });
    },
    ToScroll(){
        window.scrollTo({
        top: 400,
        behavior: 'smooth'
      });
    }
  },
};
</script>
  
<style lang="scss" scoped>
.content {
  // height: 100vh;
  position: relative;
  background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/index/big_bg.png") no-repeat;
  background-size: 100% 100%;

  .tips {
    display: flex;
    align-items: center;
    height: 42px;
    background-color: #ffd77f;
    padding: 0 12px;
    justify-content: space-between;

    .order-desc {
      display: flex;
      font-size: 14px;
      color: #333;
      align-items: center;
    }

    .go-order {
      width: 79px;
      height: 29px;
      border-radius: 16px;
      text-align: center;
      line-height: 28px;
      font-size: 13px;
      font-weight: 500;
      background-color: #2b2d5d;
      color: #fff;
      margin-right: 12px;
    }

    .close-tips {
      color: #896c35;
      font-size: 12px;
      margin-left: 5px;
    }
  }

  .banner {
    height: 370px;
    background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/index/banner.png") no-repeat;
    background-size: 100% 100%;
  }
  /deep/.van-swipe__indicator--active {
    width: 16px;
  }
  /deep/.van-swipe__indicator {
    border-radius: 4px;
  }
  .posiBox {
    background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/index/big_bg.png") no-repeat;
    background-size: 100% 100%;
    // position: absolute;
    // top: 350px;
    width: 100%;

    .rowP0 {
      // position: absolute;
      // top: 0;
      image {
        width: 100%;
      }
    }

    .rowImg {
      // text-align: center;
      position: relative;
      padding: 0 14px;

      img {
        width: 100%;
      }

      .tip {
        width: 70%;
        margin: 19px 0;
      }

      .fill {
        width: 100%;
      }

      .brief {
        margin-top: 24px;
      }

      .label {
        position: absolute;
        top: 5px;
        left: 0;
        width: 68px;
        height: 66px;
        z-index: 99;
      }
    }
    .box_bg1 {
      margin: 30px 10px;
      padding: 30px 20px 20px;
      background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/index/smmm_bg.png") no-repeat;
      background-size: 100% 100%;
      .title {
        width: 300px;
        text-align: center;
        display: flex;
        flex: 1;
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
    .box_bg2 {
      margin: 30px 10px;
      padding: 30px 20px 20px;
      background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/index/yh_bg.png") no-repeat;
      background-size: 100% 100%;
      .title {
        width: 220px;
        text-align: center;
        display: flex;
        flex: 1;
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
    .boxItem {
      position: relative;
      img {
        width: 100%;
      }
    }

    .commentBg {
      // background: url("https://qiniu.zhiyunys.com/hmcs/static/index/comment_bg.png") no-repeat;
      // background-size: 100% 100%;
      background: #ffe8c8;
      border: 1px solid #ffdca5;
      box-shadow: 4px 6px 15px 0px rgba(95, 12, 13, 0.71);
      border-radius: 30px;
      margin: 0 14px 15px;
      height: 420px;
      overflow: hidden;

      &_container {
        overflow: hidden;
        padding: 10px 20px;
        margin: 65px 0 40px;
        width: 100%;
        padding-bottom: 10px;
        overflow: hidden;
      }

      /* 隐藏溢出部分 */
      .scrollContent {
        animation: scroll 30s linear infinite;
      }

      &_item {
        margin-bottom: 15px;
        border-bottom: 1px dashed #a4a4a4;
        padding-bottom: 7px;

        .row_1 {
          font-size: 13px;
          font-weight: 500;
          color: #ff1a1a;
        }

        .row_2 {
          font-size: 13px;
          font-weight: 500;
          color: #a4a4a4;
          margin-left: 40px;
        }
      }
    }

    .mydt-notice {
      width: 315px;
      height: 383px;
      margin: 20px auto;
      overflow: hidden;

      .mydt-content {
        margin: 20px auto 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .mydt-content-item {
        .row_1 {
          font-size: 13px;
          font-weight: 500;
          color: #ff1a1a;
        }

        .row_2 {
          font-size: 13px;
          font-weight: 500;
          color: #a4a4a4;
          margin-left: 40px;
        }

        span {
          font-size: 14px;
          // font-family: PingFang SC;
          // font-weight: 400;
          // color: #946a4b;
          // line-height: 40px;
          // margin-top: 14px;
        }
      }
    }

    @keyframes scroll {
      0% {
        transform: translateY(0);
        /* 初始位置 */
      }

      100% {
        transform: translateY(-100%);
        /* 滚动到最上方 */
      }
    }

    .introduce {
      padding: 0 30px;

      > view {
        width: 50%;

        img {
          width: 100%;
        }
      }
    }

    .AQText {
      font-size: 15px;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      padding: 30px 0;
    }
  }
}
.fixedBox {
  position: fixed;
  bottom: 15px;
  width: 260px;
  height: 50px;
  line-height: 50px;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
  -webkit-animation-name: scaleDraw;
  /*关键帧名称*/
  -webkit-animation-timing-function: ease-in-out;
  /*动画的速度曲线*/
  -webkit-animation-iteration-count: infinite;
  /*动画播放的次数*/
  -webkit-animation-duration: 2s;
  background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/pay/title_bg.png") no-repeat;
  background-size: 100% 100%;
  /*动画所花费的时间*/
  .send {
    font-size: 16px;
    font-weight: 500;
    color: #fdebbd;
  }
}

@keyframes scaleDraw {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.1);
  }
}
/deep/.van-swipe-item > img {
  width: 100%;
}
</style>
  