<template>
    <div class="pay-result">
        <div class="header">
            <svg t="1655108839342" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                p-id="2220" width="64" height="64">
                <path
                    d="M654.688607 340.576674A255.737503 255.737503 0 0 0 767.9975 128.00875a95.999063 95.999063 0 0 0-153.5985-76.80925 127.99875 127.99875 0 0 0-204.798 0A95.999063 95.999063 0 0 0 256.0025 128.00875a255.737503 255.737503 0 0 0 113.308893 212.567924C173.893302 401.336081 32.004687 583.594301 32.004687 799.002197c0 124.258787 100.739016 224.997803 224.997803 224.997803h509.99502c124.258787 0 224.997803-100.739016 224.997803-224.997803 0-215.407896-141.888614-397.666117-337.306706-458.425523z m-278.457281-76.79925A190.738137 190.738137 0 0 1 320.001875 128.00875a31.999688 31.999688 0 0 1 31.999687-31.999688 31.669691 31.669691 0 0 1 19.159813 6.369938 63.999375 63.999375 0 0 0 89.609125-12.729875 63.999375 63.999375 0 0 1 102.459 0 63.999375 63.999375 0 0 0 89.609125 12.729875A31.659691 31.659691 0 0 1 671.998438 96.009062a31.999688 31.999688 0 0 1 31.999687 31.999688 191.998125 191.998125 0 0 1-175.458287 191.288132Q520.299919 319.006885 512 319.006885q-8.299919 0-16.529839 0.289997a190.548139 190.548139 0 0 1-119.228835-55.529458z m504.605072 649.063662A159.998438 159.998438 0 0 1 766.99751 960.000625H257.00249A160.998428 160.998428 0 0 1 96.004062 799.002197a415.555942 415.555942 0 0 1 398.226112-415.615941c5.869943 0.399996 11.789885 0.619994 17.769826 0.619994s11.889884-0.219998 17.769826-0.619994A415.575942 415.575942 0 0 1 927.995938 799.002197a159.998438 159.998438 0 0 1-47.15954 113.838889z"
                    p-id="2221" fill="#ffffff"></path>
                <path
                    d="M630.628842 521.374908a31.999688 31.999688 0 0 0-45.259558 0L512 594.754192l-73.369284-73.369284a31.999688 31.999688 0 0 0-45.249558 45.259558L466.740442 640.00375H416.000937a31.999688 31.999688 0 0 0 0 63.999375h63.999375v63.999375h-63.999375a31.999688 31.999688 0 0 0 0 63.999375h63.999375v31.999687a31.999688 31.999688 0 0 0 63.999376 0v-31.999687h63.999375a31.999688 31.999688 0 0 0 0-63.999375h-63.999375v-63.999375h63.999375a31.999688 31.999688 0 0 0 0-63.999375h-50.739505l73.369284-73.369284a31.999688 31.999688 0 0 0 0-45.259558z"
                    p-id="2222" fill="#ffffff"></path>
            </svg>
        </div>
        <div class="tips">
            <p>如果你已付款，请点击“<span>立即查看结果</span>”</p>
            <p>如果未完成付款，请点击“<span>继续支付</span>”</p>
        </div>
        <div class="btns">
            <span @click="check">立即查看结果</span>
            <span @click="pay">继续支付</span>
        </div>
        <div class="help">支付遇到问题？<span @click="$router.push('/order')">联系客服</span></div>
    </div>
</template>
  
<script>
import api from "@/apis/index";

export default {
    data() {
        return {
            info: {},
            redPacket: 0,
        };
    },
    mounted() {
        if (localStorage.getItem('redPacket' + this.$route.query.odd)) {
            this.redPacket = localStorage.getItem('redPacket' + this.$route.query.odd)
        }
    },
    methods: {
        isMobile() {
            if (
                window.navigator.userAgent.match(
                    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
                )
            ) {
                return true; // 移动端
            } else {
                return false; // PC端
            }
        },
        alipay() {
            api.alipay1({
                    type: this.isMobile() ? "mobile" : "pc",
                    odd: localStorage.getItem("odd"),
                    redPacket: this.redPacket,
                    // url: `https://${document.domain}/zhougong/payResult?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&bd_vid=${this.$route.query.bd_vid}`, // 一般
                    // url: `https://${document.domain}/zhougong/payResult?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&qhclickid=${this.$route.query.qhclickid}`, // 360
                    url: location.href.replace(this.$route.path, '/payResult'), // 神马
                    Channel: this.$route.query.apkChannel,
                    // domain: `https://${document.domain}/zhougong/payResult?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&bd_vid=${this.$route.query.bd_vid}`, // 一般
                    // domain: `https://${document.domain}/zhougong/payResult?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&qhclickid=${this.$route.query.qhclickid}`, // 360
                    domain: location.href.replace(this.$route.path, '/payResult'), // 神马
                })
                .then((res) => {
                    let divForm = document.getElementsByTagName("divform");
                    if (divForm.length) {
                        document.body.removeChild(divForm[0]);
                    }
                    const div = document.createElement("divform");
                    div.innerHTML = res;
                    document.body.appendChild(div);
                    document.getElementById("alipaysubmit").submit();
                });
        },
        wxpay() {
            if (this.isMobile()) {
                api.wxpay1({
                        type: this.isMobile() ? "mobile" : "pc",
                        odd: localStorage.getItem("odd"),
                        redPacket: this.redPacket,
                        // url: `https://${document.domain}/zhougong/payResult?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&bd_vid=${this.$route.query.bd_vid}`, // 一般
                        // url: `https://${document.domain}/zhougong/payResult?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&qhclickid=${this.$route.query.qhclickid}`, // 360
                        url: location.href.replace(this.$route.path, '/payResult'), // 神马
                        Channel: this.$route.query.apkChannel,
                        // domain: `https://${document.domain}/zhougong/payResult?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&bd_vid=${this.$route.query.bd_vid}`, // 一般
                        // domain: `https://${document.domain}/zhougong/payResult?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&qhclickid=${this.$route.query.qhclickid}`, // 360
                        domain: location.href.replace(this.$route.path, '/payResult'), // 神马
                    })
                    .then((res) => {
                        location.href = res.code_url;
                    });
            } else {
                api
                    .wxpay1({
                        type: this.isMobile() ? "mobile" : "pc",
                        odd: localStorage.getItem("odd"),
                        redPacket: this.redPacket,
                        // url: `https://${document.domain}/zhougong/payResult?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&bd_vid=${this.$route.query.bd_vid}`, // 一般
                        // url: `https://${document.domain}/zhougong/payResult?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&qhclickid=${this.$route.query.qhclickid}`, // 360
                        url: location.href.replace(this.$route.path, '/payResult'), // 神马
                        Channel: this.$route.query.apkChannel,
                        // domain: `https://${document.domain}/zhougong/payResult?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&bd_vid=${this.$route.query.bd_vid}`, // 一般
                        // domain: `https://${document.domain}/zhougong/payResult?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&qhclickid=${this.$route.query.qhclickid}`, // 360
                        domain: location.href.replace(this.$route.path, '/payResult'), // 神马
                    })
                    .then((res) => {
                        this.$router.push({
                            path: "/wxPay",
                            query: {
                                info: JSON.stringify(res),
                            },
                        });
                    });
            }
        },
        pay() {
            api.getOrder({
                    odd: localStorage.getItem("odd"),
                    orderType: '眼皮跳'
                })
                .then((res) => {
                    console.log(res);
                    if (!res.message) {
                        this.info = res[0];
                        if (this.info.status === 1) {
                            this.$toast('已经支付过该订单！');
                        } else {
                            const payway = localStorage.getItem('payway');
                            if (payway === 'wx') {
                                this.wxpay();
                            } else if (payway === 'ali') {
                                this.alipay();
                            } else {
                                // this.$toast('支付方式丢失！');
                                this.$router.push({
                                    path: `/ResultPayed`,
                                    query: {
                                        odd: localStorage.getItem("odd")
                                    }
                                });
                            }
                        }
                    } else {
                        this.$toast(res.message);
                    }
                });
        },
        check() {
            api.getOrder({
                    odd: localStorage.getItem("odd"),
                    orderType: '眼皮跳'
                })
                .then((res) => {
                    if (!res.message) {
                        this.info = res[0];
                        if (this.info.status === 1) {
                            this.$router.push({
                                path: '/success',
                                query: {
                                    status: 1
                                }
                            });
                        } else {
                            this.$toast('该订单尚未支付，请点击继续支付！');
                        }
                    } else {
                        this.$toast(res.message);
                    }
                });
        },
    }
}
</script>
  
<style lang="scss" scoped>
.pay-result {
    position: relative;
    padding-bottom: 220px;
    font-size: 0.23rem;

    .header {
        height: 24vh;
        background: linear-gradient(to right, #59ab4b, #6fc366);
        margin-bottom: 40px;
        position: relative;

        .icon {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .tips {
        padding: 0 30px;

        p {
            line-height: 2;
            font-size: 14px;
            text-align: center;

            span {
                color: #59ab4b;
            }
        }
    }

    .btns {
        padding: 30px;

        span {
            display: block;
            height: 44px;
            line-height: 44px;
            font-size: 14px;
            text-align: center;
            border-radius: 24px;
            margin-bottom: 20px;
            border: 1px solid #59ab4b;
            cursor: pointer;

            &:first-child {
                color: #fff;
                background: linear-gradient(to right, #59ab4b 70%, #6fc366);
            }

            &:last-child {
                color: #59ab4b;
                background: #fff;
            }
        }
    }

    .help {
        color: #bbb;
        font-size: 0.23rem;
        text-align: center;
        width: 100%;
        position: absolute;
        bottom: 60px;
        left: 0;

        span {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}</style>