import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

import less from 'less'
Vue.use(less)

import vant from 'vant';
import { Lazyload, Toast, DatetimePicker, Popup, RadioGroup, Radio } from 'vant';
import 'vant/lib/index.css';
Vue.use(vant).use(Lazyload).use(Toast).use(DatetimePicker).use(Popup).use(RadioGroup).use(Radio);

import fastClick from 'fastclick';
fastClick.attach(document.body);
fastClick.prototype.needsFocus = function (target) { //判断当前元素是否需要focus
  switch (target.nodeName.toLowerCase()) {
    case 'textarea':
      return true;
    case 'select':
      return !(navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1);
    case 'input':
      switch (target.type) {
        case 'button':
        case 'checkbox':
        case 'file':
        case 'image':
        case 'radio':
        case 'submit':
          return false;
      }
      // No point in attempting to focus disabled inputs
      return !target.disabled && !target.readOnly;
    default:
      return (/\bneedsfocus\b/).test(target.className);
  }
};
fastClick.prototype.focus = function (targetElement) {
  var length;
  //兼容处理:在iOS7中，有一些元素（如date、datetime、month等）在setSelectionRange会出现TypeError
  //这是因为这些元素并没有selectionStart和selectionEnd的整型数字属性，所以一旦引用就会报错，因此排除这些属性才使用setSelectionRange方法
  if (!!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) && targetElement.setSelectionRange && targetElement.type.indexOf('date') !== 0 && targetElement.type !== 'time' && targetElement.type !== 'month' && targetElement.type !== 'email') {
    length = targetElement.value.length;
    targetElement.setSelectionRange(length, length);
    /*修复bug ios 11.3不弹出键盘，这里加上聚焦代码，让其强制聚焦弹出键盘*/
    targetElement.focus();
  } else {
    targetElement.focus();
  }
};
function setRootFontSize() {
  var width = document.documentElement.clientWidth || document.body.clientWidth;
  if (width > 460) {
    width = 460;
  }
  const fontSize = (width * 0.1);
  document.getElementsByTagName('html')[0].style['font-size'] = fontSize + 'px';
}
setRootFontSize();
window.addEventListener('resize', function () {
  setRootFontSize();
}, false);

Date.prototype.Format = function (fmt) { //author: meizz 
  var o = {
    "M+": this.getMonth() + 1, //月份 
    "d+": this.getDate(), //日 
    "h+": this.getHours(), //小时 
    "m+": this.getMinutes(), //分 
    "s+": this.getSeconds(), //秒 
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
    "S": this.getMilliseconds() //毫秒 
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
