<template>
    <div class="resultContainer">
        <div class="resultBox">
            <div class="result_img mb10">
                <img mode="widthFix" src="https://qiniu.zhiyunys.com/yanpitiao/sacc/pay/person.png" />
            </div>
            <div class="box_bg1 boxItem">
                <div class="title">
                    <img mode="widthFix" src="https://qiniu.zhiyunys.com/yanpitiao/sacc/pay/ndzl.png" />
                </div>
                <div class="infoBox">
                    <div class="txt">您的姓名：{{ info.userName }}</div>
                    <div class="txt">左右眼皮：{{ info.sundrys }} </div>
                    <div class="txt">您的生日：{{ info.usergBirthTime }} </div>
                    <div class="txt">您的八字：{{ info.horoscope }}</div>
                </div>
            </div>
            <div class="box_bg2 boxItem">
                <div class="titleImg">
                    <img mode="widthFix" src="https://qiniu.zhiyunys.com/yanpitiao/sacc/pay/price_bg.png" />
                </div>
                <div class="unlock">
                    付费后您将解锁<span style="font-size: 18px;"> 5 </span>大分析报告
                </div>
                <img class="mt5" mode="widthFix" src="https://qiniu.zhiyunys.com/yanpitiao/sacc/pay/items5.png" />
                <div class="resultType">测算项目：眼皮跳动凶吉</div>
                <div class="priceRow flex flexrow alic">
                    <span class="title">结缘价：</span>
                    <div class="price flex alic">
                        <span class="money">{{ totalPrice }}</span>
                        <span class="yuan">元</span>
                    </div>
                    <div class="yuanjia">原价:122元</div>
                </div>
                <div class="payTypeRow flex flexrow">
                    <span class="title">支付方式：</span>
                    <div class="payItem flex flexcol flex_col_center" v-for="(item, index) in payTypeList" :key="index"
                        @click="selectedPayType(index)">
                        <img class="icon_pay" :src="item.icon" />
                        <span class="txt">{{ item.name }}</span>
                        <img class="icon_selected" v-if="item.checked"
                            src="https://qiniu.zhiyunys.com/yanpitiao/sacc/index/icon_seleted.png" />
                        <img class="icon_selected" v-else
                            src="https://qiniu.zhiyunys.com/yanpitiao/sacc/index/icon_notSelect.png" />
                    </div>
                </div>
                <div style="text-align: center">
                    <span class="title">距离优惠：</span>
                    <span class="countTime">{{ payTime }}</span>
                </div>
            </div>
            <div class="reprotBox">
                <div class="titleImg">
                    <img mode="widthFix" src="https://qiniu.zhiyunys.com/yanpitiao/sacc/pay/fxbg.png" />
                </div>
                <div class="reprotBox_title">结合您的生命密码分析您的人生运势</div>
                <div class="reprotBox_item" v-for="(item, index) in reprotList" :key="index">
                    <div class="flex flexrow juststart alic mt10">
                        <div class="icon_current">0{{ index + 1 }}</div>
                        <div class="title">{{ item.title }}</div>
                    </div>
                    <div class="desBox">
                        <div class="blur" style=""></div>
                        <span class="des">{{ item.des }}</span>
                    </div>
                </div>
                <div class="submitBox row2 mt10" @click="toSubmit">
                    <p class="send">立即付费解锁报告内容</p>
                    <!-- <span>立即付费解锁报告内容</span>
                    <img mode="widthFix" src="https://qiniu.zhiyunys.com/yanpitiao/sacc/pay/title_bg.png" /> -->
                </div>
            </div>

            <div class="flex flex_col flex_col_center">
                <div v-if="scrollTop < 600" class="fixedBox" @click="toSubmit">
                    <p class="send">立即付费解锁报告内容</p>
                    <!-- <img mode="widthFix" src="https://qiniu.zhiyunys.com/yanpitiao/sacc/pay/title_bg.png" /> -->
                </div>
            </div>
        </div>
        <div class="fix-order">
            <img src="https://qiniu.zhiyunys.com/img/order-check.png" @click="$router.push('/order')" />
        </div>
        <!-- 返回事件 红包弹窗 -->
        <div class="pay-return" v-if="showCountDown">
            <div class="pay-return-content">
                <h4>恭喜获得红包！</h4>
                <div class="pay-return-price">
                    <p><span>10.00</span>元</p>
                    <p>订单金额 &gt; 10.00元可用</p>
                    <p>可在购买测算时进行抵扣</p>
                </div>
                <div class="pay-return-time">
                    <div class="timer" style="margin-right:5px">
                        <span>{{ formatTime(tenMinutes.minutes) }}</span>:
                        <span>{{ formatTime(tenMinutes.seconds) }}</span>:
                        <span>{{ formatMilliseconds(tenMinutes.milliseconds) }}</span>
                    </div>后失效
                </div>
                <button class="pay-return-btn" @click="getRedPacket()">立即领取</button>
            </div>
        </div>
    </div>
</template>
  
<script>
import api from "@/apis/index";
export default {
    name: "",
    data() {
        return {
            payTypeList: [
                { name: "支付宝", icon: require('/static/sacc/pay/alipay.png'), checked: 0 },
                { name: "微信", icon: require('/static/sacc/pay/wechat.png'), checked: 1 },
            ],
            payTime: "00:00:00",
            reprotList: [
                {
                    title: "眼皮跳对您事业和财运的影响",
                    des: "航司不再查验来华人员登机前核酸航司不再查验来华人员登机前核酸",
                },
                {
                    title: "眼皮跳对您爱情的影响",
                    des: "您的号码属于那种可以发财致富的，号码非常珍贵少见，会给你带来好运，号码要长期使用给你带来好运",
                },
                {
                    title: "眼皮跳对您健康的影响",
                    des: "号码不会给您的婚姻带来任何影响，反而会给你带来好运，不信你可支付问问我的大师",
                },
            ],
            payType: 2, //1=>支付宝 ； 2=>微信
            info: {},
            price: localStorage.getItem("price"),
            redPacket: 0,
            scrollTop: 0,
            tenMinutes: {
                minutes: 10,
                seconds: 0,
                milliseconds: 0
            },
            showCountDown: false
        };
    },
    computed: {
        totalPrice() {
            const result = (this.info.price - this.redPacket).toFixed(2); // 保留两位小数
            return Number(result).toString();
        }
    },
    mounted() {
        window.addEventListener('popstate', this.handleBackButton);
        window.addEventListener('popstate', this.handleBackButton);
        history.pushState(null, null, document.URL);
        if (localStorage.getItem('redPacket' + this.$route.query.odd)) {
            this.redPacket = localStorage.getItem('redPacket' + this.$route.query.odd)
        }
        this.getOrder();
        // 获取订单创建时间
        const startTime = new Date();
        this.handleTime(startTime);
        window.addEventListener("scroll", () => {
            this.scrollTop = document.documentElement.scrollTop;
        });
        // if (window.history && window.history.pushState) {
        //     // 往历史记录里面添加一条新的当前页面的url
        //     history.pushState(null, null, document.URL);
        //     // 给 popstate 绑定一个方法用来监听页面返回
        //     window.addEventListener('popstate', this.handleBackButton, false); //false阻止默认事件
        // }
    },
    destroyed() {
        window.removeEventListener('hashchange', this.handleBackButton);
        window.removeEventListener('popstate', this.handleBackButton);
    },

    methods: {
        async getOrder() {
            let data = {
                odd: localStorage.getItem("odd"),
                orderType: "眼皮跳",
            };
            try {
                const res = await api.getOrder(data);
                console.log("订单信息", res);
                // if (res.code != 0) return this.$api.msg(res.message)
                this.info = res[0] || {};
                // 返回字段status提示：0为待支付，1为已支付，存在多余字段，只取有用字段
                console.log("11", this.info);
                if (res[0].status == 1) {
                    this.$router.push({
                        path: "/success",
                        query: {
                            status: 1,
                        },
                    });
                }
            } catch (error) { }
        },
        selectedPayType(index) {
            if (index == 0) {
                this.payTypeList[0].checked = 1;
                this.payTypeList[1].checked = 0;
                this.payType = 1;
            } else {
                this.payTypeList[1].checked = 1;
                this.payTypeList[0].checked = 0;
                this.payType = 2;
            }
        },
        checkTime(time) {
            return (time = time < 10 ? `0${time}` : time);
        },
        handleTime(expireTime) {
            let that = this;
            setInterval(() => {
                const now = Date.now(); // 当前时间戳
                const remainingTime =
                    1 * 60 * 60 * 1000 - (now - new Date(expireTime).getTime()); // 剩余时间（毫秒）
                const remainingHours = Math.floor(remainingTime / (60 * 60 * 1000)); // 剩余小时数
                const remainingMinutes = Math.floor(
                    (remainingTime % (60 * 60 * 1000)) / (60 * 1000)
                ); // 剩余分钟数
                const remainingSeconds = Math.floor(
                    (remainingTime % (60 * 1000)) / 1000
                ); // 剩余秒数
                that.payTime = `${that.checkTime(remainingHours)}:${that.checkTime(
                    remainingMinutes
                )}:${that.checkTime(remainingSeconds)}`; // 更新剩余时间
            }, 1000);
        },
        toSubmit() {
            if (!this.payType) return this.$api.msg("请选择支付方式");
            if (this.payType == 1) {
                this.handleAlipay();
            } else {
                this.wxpay();
            }
        },
        async handleAlipay() {
            localStorage.setItem("payway", "ali");
            let data = {
                type: this.isMobile() ? "mobile" : "pc",
                odd: localStorage.getItem("odd"),
                redPacket: this.redPacket,
                url: location.href.replace(this.$route.path, "/pay"), // 神马
                Channel: this.$route.query.apkChannel,
                domain: location.href.replace(this.$route.path, "/payResult"), // 神马
            };
            try {
                const res = await api.alipay1(data);
                console.log("支付宝支付", res);
                // if (res.code != 0) return this.$api.msg(res.message);
                let divForm = document.getElementsByTagName("divform");
                if (divForm.length) {
                    document.body.removeChild(divForm[0]);
                }
                const div = document.createElement("divform");
                div.innerHTML = res;
                document.body.appendChild(div);
                document.getElementById("alipaysubmit").submit();
            } catch (error) {
                console.log("error", error);
            }
        },
        async wxpay() {
            localStorage.setItem("payway", "wx");
            let data = {
                type: "",
                odd: localStorage.getItem("odd"),
                redPacket: this.redPacket,
                url: location.href.replace(this.$route.path, "/pay"), // 来源全链接
                Channel: this.$route.query.apkChannel,
                domain: location.href.replace(this.$route.path, "/payResult"), // 支付成功返回路径
            };
            if (this.isMobile()) {
                data.type = "mobile";
            } else {
                data.type = "pc";
            }
            try {
                const res = await api.wxpay1(data);
                console.log("微信支付", res.code_url);
                // if (res.code != 0) return this.$api.msg(res.message);
                if (this.isMobile()) {
                    window.location.href = res.code_url;
                } else {
                    this.$router.push({
                        path: "/wxPay",
                        query: {
                            info: JSON.stringify(res),
                        },
                    });
                }
            } catch (error) { }
        },
        isMobile() {
            if (
                window.navigator.userAgent.match(
                    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
                )
            ) {
                return true; // 移动端
            } else {
                return false; // PC端
            }
        },
        handleBackButton(event) {
            // 右滑返回事件触发时的处理逻辑
            // window.location.reload()
            event.preventDefault() // 阻止默认的返回行为
            console.log('返回');
            this.showCountDown = true;
            this.startTimer();
        },
        startTimer() {
            let totalMilliseconds = this.tenMinutes.minutes * 60000 + this.tenMinutes.seconds * 1000;
            const timer = setInterval(() => {
                if (totalMilliseconds > 0) {
                    totalMilliseconds -= 10;
                    this.tenMinutes.minutes = Math.floor(totalMilliseconds / 60000);
                    this.tenMinutes.seconds = Math.floor((totalMilliseconds % 60000) / 1000);
                    this.tenMinutes.milliseconds = totalMilliseconds % 1000;
                } else {
                    clearInterval(timer);
                }
            }, 10);
        },
        formatTime(time) {
            return String(time).padStart(2, '0');
        },
        formatMilliseconds(milliseconds) {
            return String(Math.floor(milliseconds / 10)).padStart(2, '0');
        },
        getRedPacket() {
            localStorage.setItem('redPacket' + this.$route.query.odd, 10);
            this.redPacket = 10;
            this.toSubmit()
        }
    },
};
</script>
<style  lang="scss" scoped>
.resultContainer {
    background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/pay/bg.png") no-repeat;
    background-size: 100% 100%;
    // height: 100vh;
    position: relative;

    .resultBox {
        padding: 15px 15px 40px;

        // display: flex;
        // flex-direction: column;
        // align-items: center;
        .result_img {
            padding: 0 15px;

            img {
                width: 100%;
            }
        }

        .infoBox {
            padding: 25px 30px;

            .txt {
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 9px;
                background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/pay/wak.png") no-repeat;
                background-size: 100% 100%;
                padding: 10px 20px;
                font-size: 16px;
                font-weight: 400;
                color: #FCEABB;
            }
        }

        .reprotBox {
            background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/pay/fxbg_bg.png") no-repeat;
            background-size: 100% 100%;
            height:420px;
            padding: 45px 30px;
            margin: 25px 10px 0;
            position: relative;

            .titleImg {
                width: 220px;
                text-align: center;
                display: flex;
                flex: 1;
                position: absolute;
                top: -20px;
                left: 50%;
                transform: translate(-50%, 0);

                img {
                    width: 100%;
                }
            }

            &_title {
                background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/pay/fxbg_title.png") no-repeat;
                background-size: 100% 100%;
                text-align: center;
                padding: 10px;
                font-size: 16px;
                font-weight: 500;
                color: #FCEBBD;
                margin-bottom: 15px;
            }

            &_item {
                font-weight: 500;
                color: #573115;

                .icon_current {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/pay/f1.png") no-repeat;
                    background-size: 100% 100%;
                    font-size: 14px;
                    font-weight: 400;
                    color: #FFFFFF;
                    margin-right: 10px;
                    text-align: center;
                }

                .title {
                    font-size: 16px;
                    margin: 13px 0;
                }

                .desBox {
                    font-size: 14px;
                    padding: 5px 10px;
                    position: relative;

                    .blur {
                        width: 100%;
                        height: 100%;
                        background: rgba(242, 242, 242, 0.1);
                        position: absolute;
                        top: 0;
                        right: 10px;
                        backdrop-filter: blur(4px);
                    }

                    .des {}
                }
            }

            .row2 {
                text-align: center;

                img {
                    width: 80%;
                }
            }

            .submitBox {
                -webkit-animation-name: scaleDraw;
                /*关键帧名称*/
                -webkit-animation-timing-function: ease-in-out;
                /*动画的速度曲线*/
                -webkit-animation-iteration-count: infinite;
                /*动画播放的次数*/
                -webkit-animation-duration: 2s;

                /*动画所花费的时间*/
                margin: 15px 30px 0;

                .send {
                    font-size: 16px;
                    font-weight: 500;
                    color: #FDEBBD;
                    background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/pay/title_bg.png") no-repeat;
                    background-size: 100% 100%;
                    padding: 15px 0;
                }
            }
        }
    }

    .fixedBox {
        position: fixed;
        bottom: 15px;
        width: 260px;
        height: 50px;
        line-height: 50px;
        margin: 0 auto;
        text-align: center;
        -webkit-animation-name: scaleDraw;
        /*关键帧名称*/
        -webkit-animation-timing-function: ease-in-out;
        /*动画的速度曲线*/
        -webkit-animation-iteration-count: infinite;
        /*动画播放的次数*/
        -webkit-animation-duration: 2s;
        background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/pay/title_bg.png") no-repeat;
        background-size: 100% 100%;
        /*动画所花费的时间*/
        .send {
            font-size: 16px;
            font-weight: 500;
            color: #FDEBBD;
        }
    }

    @keyframes scaleDraw {
        0% {
            transform: scale(1);
        }

        25% {
            transform: scale(1.1);
        }

        50% {
            transform: scale(1);
        }

        75% {
            transform: scale(1.1);
        }
    }

    .pay-return {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, .65);
        z-index: 11
    }


    .pay-return-content {
        position: fixed;
        width: 280px;
        height: 310px;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        background: url(//zx.tengzhihh.com/bdmllxiabannianyunshi/images/coupon_pop.e677a4f.png) 0 0 no-repeat;
        background-size: 100% 100%;
        padding: 20px 0;
        text-align: center
    }

    .pay-return-content h4 {
        margin-top: 20px;
        font-size: 24px;
        color: #db8014
    }

    .pay-return-price p:first-child {
        padding-top: 10px;
        font-size: 32px;
        color: #df062a
    }

    .pay-return-price p:first-child span {
        font-size: 42px;
    }

    .pay-return-price p:nth-child(2) {
        width: 160px;
        background: rgba(255, 141, 48, .24);
        border: 1px solid rgba(188, 78, 14, .24);
        margin: 0 auto;
        color: #bd5815;
        font-size: 14px;
        margin-top: 5px;
        padding: 5px 0;
    }

    .pay-return-price p:nth-child(3) {
        color: #bc4e0e;
        font-size: 14px;
        opacity: .5;
        margin-top: 5px;
    }

    .pay-return-time {
        margin-top: 65px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 18px
    }

    .pay-return-time span {
        display: inline-block;
        vertical-align: middle;
        width: 30px;
        height: 30px;
        border: 1px solid #ff4e50;
        background: #ca1929;
        text-align: center;
        line-height: 30px;
    }

    .pay-return-btn {
        margin-top: 20px;
        width: 150px;
        height: 40px;
        border-radius: 50px;
        -webkit-box-shadow: 0 .4688rem .5625rem 0 rgba(138, 0, 12, .26), inset 0 .2813rem .5625rem 0 #fff;
        box-shadow: 0 .4688rem .5625rem 0 rgba(138, 0, 12, .26), inset 0 .2813rem .5625rem 0 #fff;
        background-color: #fb9d3f;
        border: none;
        font-size: 24px;
        color: #6c3000;
        font-weight: 700
    }
}

.unlock {
    font-size: 16px;
    font-family: FZBeiWeiKaiShu-S19S;
    font-weight: 400;
    color: #800809;
    text-align: center;
    padding: 20px 0;
}

.box_bg1 {
    margin: 30px 10px;
    padding: 30px 20px 20px;
    background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/pay/ndzl_bg.png") no-repeat;
    background-size: 100% 100%;

    .title {
        width: 220px;
        text-align: center;
        display: flex;
        flex: 1;
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translate(-50%, 0);
    }
}

.box_bg2 {
    margin: 30px 10px;
    padding: 30px 20px 20px;
    background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/pay/fxbg_bg.png") no-repeat;
    background-size: 100% 100%;

    .titleImg {
        width: 220px;
        text-align: center;
        display: flex;
        flex: 1;
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .resultType {
        background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/pay/title_bg.png") no-repeat;
        background-size: 100% 100%;
        text-align: center;
        padding: 15px 0;
        font-size: 16px;
        font-weight: 500;
        color: #FAE4B5;
        ;
        margin: 10px 30px;
    }

    .priceRow {
        margin-top: 10px;

        .price {
            flex: 1;

            .money {
                font-size: 24px;
                font-weight: 400;
                color: #800809;
            }

            .yuan {
                color: #800809;
                font-size: 18px;
            }
        }

        .yuanjia {
            font-size: 18px;
            font-weight: 500;
            text-decoration: line-through;
            color: #B84E4F;
            opacity: 0.76;
        }
    }

    .title {
        font-size: 17px;
        font-weight: 500;
        color: #800809;
    }

    .payTypeRow {
        margin: 15px 0;
        text-align: center;

        .payItem {
            margin-right: 15px;

            .icon_pay {
                width: 34px;
                height: 34px;
            }

            .icon_selected {
                width: 15px;
                height: 15px;
            }

            .txt {
                font-size: 14px;
                font-weight: 400;
                color: #800809;
                margin: 10px 0;
            }
        }
    }

    .countTime {
        font-size: 20px;
        font-weight: 400;
        color: #800809;
    }
}

.boxItem {
    position: relative;

    img {
        width: 100%;
    }
}
</style>