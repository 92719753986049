import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '@/pages/Main'
import ResultPayed from '@/pages/ResultPayed'
import PayResult from '@/pages/PayResult'
import Order from '@/pages/Order'
import WxPay from '@/pages/WxPay'
import Guide from '@/pages/Guide'
import Help from '@/pages/Help'
import Success from '@/pages/Success'
import Wrapper from '@/pages/Wrapper'
import Protocol from '@/pages/Protocol'
import loadingResult from '@/pages/LoadingResult'
import Pay from '@/pages/Pay'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  base: '/yanpitiao/',
  routes: [
    { path: '/', redirect: '/main', meta: { isPush: true } },
    { path: '/main', component: Main, meta: { isPush: true } },
    { path: '/order', component: Order, meta: { isPush: true } },
    { path: '/resultPayed', component: ResultPayed, meta: { isPush: true } },
    { path: '/payResult', component: PayResult, meta: { isPush: true } },
    { path: '/wxPay', component: WxPay, meta: { isPush: true } },
    { path: '/guide', component: Guide, meta: { isPush: true } },
    { path: '/help', component: Help, meta: { isPush: true } },
    { path: '/success', component: Success, meta: { isPush: true } },
    { path: '/wrapper', component: Wrapper, meta: { isPush: true } },
    { path: '/Protocol', component: Protocol, meta: { isPush: true } },
    { path: '/loadingResult', component: loadingResult, meta: { isPush: true } },
    { path: '/Pay', component: Pay, meta: { isPush: true } },
    
  ]
})

router.beforeEach((to, from, next) => {
  let query = { ...to.query };
  if (!to.query.apkChannel) {
    // query.apkChannel = 'BD_zwds01_ty_A04_lh';
    query.apkChannel = from.query.apkChannel || 'noChannel';
  }
  if (!to.query.isCallBack) {
    query.isCallBack = from.query.isCallBack || 0;
  }
  if (!to.query.bd_vid) { // 百度
    query.bd_vid = from.query.bd_vid;
  }
  if (!to.query.uctrackid) { // 神马
    query.uctrackid = from.query.uctrackid;
  }
  if (!to.query.pageId) { // 志云
    query.pageId = from.query.pageId;
  }
  if (!to.query.tid) { // 志云
    query.tid = from.query.tid;
  }
  if (!to.query.lbid) { // 志云
    query.lbid = from.query.lbid;
  }
  if (to.query.apkChannel) {
    next();
  } else {
    next({
      path: to.path === '/' ? '' : to.path,
      query,
    });
  }
})

// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   route
// })

export default router
