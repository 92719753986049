<template>
    <div class="resultBox">
        <div class="headerBox">
            <div class="headerBox_img"> {{ userInfo.userName }}的八字解析</div>
            <div v-if="currentIndex == 0" class="headerBox_title">
                <div class="txt flex flexrow justc">
                    <span class="txt_left">姓名</span>
                    <div class="txt_right">{{ userInfo.userName }}</div>
                </div>
                <div class="txt flex flexrow justc">
                    <span class="txt_left">生日</span>
                    <div class="txt_right">{{ userInfo.usergBirthTime }}</div>
                </div>
                <div class="txt flex flexrow justc">
                    <span class="txt_left">左右眼皮</span>
                    <div class="txt_right">{{ userInfo.eyelid }}</div>
                </div>
            </div>
            <div v-show="currentIndex != 0" class="desc bz">
                <div class="list" v-for="(item, index) in descList" :key="index">
                    <div class="first">{{ item.first }}</div>
                    <div class="second">{{ item.second }}</div>
                    <div class="third">{{ item.third }}</div>
                </div>
            </div>
        </div>
        <!-- tab内容动画 -->
        <div class="info">
            <div class="tab">
                <div :class="`tab-list ${tabActive === item ? 'active' : ''}`" v-for="(item, index) in tabs" :key="index">
                    {{ item }}
                </div>
            </div>
            <div class="line">
                <img mode="widthFix" src="https://qiniu.zhiyunys.com/yanpitiao/sacc/turn/3.png"/>
            </div>
            <div class="content" :style="`visibility: ${tabActive === tabs[0] ? 'visible' : 'hidden'}`">
                <div class="content-list">
                    <div>正在录入命主信息</div>
                    <div class="bar">
                        <span class="bar-list"></span>
                    </div>
                </div>
                <div class="content-list">
                    <div>正在生成八字</div>
                    <div class="bar">
                        <span class="bar-list"></span>
                    </div>
                </div>
            </div>
            <div class="content" :style="`visibility: ${tabActive === tabs[1] ? 'visible' : 'hidden'}`">
                <div class="content-list">
                    <div>正在分析你的眼皮跳凶吉</div>
                    <div class="bar">
                        <span class="bar-list"></span>
                    </div>
                </div>
                <div class="content-list">
                    <div>正在分析您的生命密码</div>
                    <div class="bar">
                        <span class="bar-list"></span>
                    </div>
                </div>
                <div class="content-list">
                    <div>正在解析生成报告内容</div>
                    <div class="bar">
                        <span class="bar-list"></span>
                    </div>
                </div>
            </div>
            <div class="content" :style="`visibility: ${tabActive === tabs[2] ? 'visible' : 'hidden'}`">
                <div class="content-list">
                    <div>正在生成你的眼皮跳凶吉报告</div>
                    <div class="bar">
                        <span class="bar-list"></span>
                    </div>
                </div>
                <div class="content-list">
                    <div>正在生成你的感情财富健康凶吉密码</div>
                    <div class="bar">
                        <span class="bar-list"></span>
                    </div>
                </div>
                <div class="content-list">
                    <div>正在生成分析报告内容</div>
                    <div class="bar">
                        <span class="bar-list"></span>
                    </div>
                </div>
            </div>
        </div>
        <!-- 转盘 -->
        <div v-if="!showTurntable">
            <div class="animation">
                <div id="outer" class="outer"></div>
                <div id="center" class="center"></div>
                <div id="inner" class="inner"></div>
                <div id="core" class="core"></div>
                <div id="light" class="light"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "",
    data() {
        return {
            currentIndex: 0,
            tabs: ["基础命盘", "眼皮跳解析", "生成报告"],
            cardAnswerList: [
                { txt: "根,庚,午" },
                { txt: "壬,庚,午" },
                { txt: "丁,庚,午" },
                { txt: "庚,庚,午" },
            ],
            descList: [],
            inner: ["根", "苗", "花", "果"],
            outer: ["壬", "葵", "甲", "乙", "丙", "丁", "戊", "己", "庚", "辛"],
            center: [
                "子",
                "未",
                "申",
                "酉",
                "戊",
                "亥",
                "子",
                "丑",
                "寅",
                "卯",
                "辰",
                "巳",
            ],
            timer: null,
            animation: 12,
            showTurntable: false, //转盘显示隐藏
            tabActive: "基础命盘",
            userInfo: {}
        };
    },
    mounted() {
        window.addEventListener('popstate', this.handlePopstate);
        this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
        console.log('userInfo',this.userInfo);
        // 跳转页面
        setTimeout(() => {
            this.showTurntable = true;
            this.$nextTick(() => {
            });
        }, this.animation * 1050);

        const obj = {
            inner1: Math.floor(Math.random() * 3),
            center1: Math.floor(Math.random() * 9),
            outer1: Math.floor(Math.random() * 11),
            inner2: Math.floor(Math.random() * 3),
            center2: Math.floor(Math.random() * 9),
            outer2: Math.floor(Math.random() * 11),
            inner3: Math.floor(Math.random() * 3),
            center3: Math.floor(Math.random() * 9),
            outer3: Math.floor(Math.random() * 11),
            inner4: Math.floor(Math.random() * 3),
            center4: Math.floor(Math.random() * 9),
            outer4: Math.floor(Math.random() * 11),
        };
        this.descList = [
            {
                first: this.inner[obj.inner1],
                second: this.center[obj.center1],
                third: this.outer[obj.outer1],
            },
            {
                first: this.inner[obj.inner2],
                second: this.center[obj.center2],
                third: this.outer[obj.outer2],
            },
            {
                first: this.inner[obj.inner3],
                second: this.center[obj.center3],
                third: this.outer[obj.outer3],
            },
            {
                first: this.inner[obj.inner4],
                second: this.center[obj.center4],
                third: this.outer[obj.outer4],
            },
        ];
        this.$nextTick(() => {
            const barList = Array.from(
                document.getElementsByClassName("bar-list")
            ).length;
            this.tabs.forEach((item, index) => {
                setTimeout(() => {
                    console.log('item',item);
                    this.tabActive = item;
                }, (this.animation / barList) * (index <= 1 ? 2 * index : 3 * index - 1) * 1000);
            });
            const timer1 = setInterval(() => {
                Array.from(document.getElementsByClassName("bar-list")).forEach(
                    (item, index) => {
                        item.style.transition = `width linear ${this.animation / barList}s`;
                        setTimeout(() => {
                            item.style.width = "100%";
                        }, index * ((this.animation / barList) * 1000));
                        if (index === barList - 1) {
                            clearInterval(timer1);
                            setTimeout(() => {
                                this.currentIndex += 1;
                                // 文字动画
                                Array.from(document.getElementsByClassName("list")).forEach(
                                    (item, index) => {
                                        setTimeout(() => {
                                            item.style.opacity = 1;
                                        }, index * 3000);
                                    }
                                );
                            }, 500);
                        }
                    }
                );
            }, 2000);
            const timer2 = setInterval(() => {
                this.$router.push('/pay')
                clearInterval(timer2);
            }, this.animation * 1050);
            // 转盘动画
            const tempTime = (this.animation - 3) / 4;
            const innerElement = document.getElementById("inner");
            const centerElement = document.getElementById("center");
            const outerElement = document.getElementById("outer");
            const coreElement = document.getElementById("core");
            [1, 2, 3, 4].forEach((index) => {
                innerElement.style.transition = `all ${tempTime}s linear`;
                centerElement.style.transition = `all ${tempTime}s linear`;
                outerElement.style.transition = `all ${tempTime}s linear`;
                coreElement.style.transition = `all ${tempTime}s linear`;
                setTimeout(() => {
                    innerElement.style.transform = `translateX(-50%) rotate(${index % 2 === 0 ? "-" : "+"
                        }${obj[`inner${index}`] === 0 ? 360 : (360 / 4) * obj[`inner${index}`]
                        }deg)`;
                }, (index - 1) * (tempTime + 1) * 1000);
                setTimeout(() => {
                    centerElement.style.transform = `translateX(-50%) rotate(${index % 2 === 0 ? "+" : "-"
                        }${obj[`inner${index}`] === 0
                            ? 360
                            : (360 / 10) * obj[`center${index}`]
                        }deg)`;
                }, (index - 1) * (tempTime + 1) * 1000);
                setTimeout(() => {
                    outerElement.style.transform = `translateX(-50%) rotate(${index % 2 === 0 ? "-" : "+"
                        }${obj[`inner${index}`] === 0 ? 360 : (360 / 12) * obj[`outer${index}`]
                        }deg)`;
                }, (index - 1) * (tempTime + 1) * 1000);
                // setTimeout(() => {
                //     coreElement.style.transform = `translateX(-50%) rotate(${index % 2 === 0 ? "-" : "+"}${obj[`inner${index}`] === 0 ? 360 : (360 / 12) * obj[`outer${index}`]}deg)`;
                // }, (index - 1) * (tempTime + 1) * 1000);
            });
        });
    },
    beforeDestroy() {
        window.removeEventListener('popstate', this.handlePopstate);
    },
    methods: {
        countDown() {
            // 预计时间
            const _this = this;
            var expectTime = new Date().getTime() + 8 * 60 * 60 * 1000;
            var nowTime = new Date().getTime(); //当前时间总的毫秒数
            var inputTime = expectTime; //用户输入时间总的毫秒数
            var times = (inputTime - nowTime) / 1000; //times是剩余时间总的毫秒数
            this.timer = setInterval(function () {
                times--;
                var h = parseInt((times / 60 / 60) % 24);
                h = h < 10 ? "0" + h : h;
                var m = parseInt((times / 60) % 60);
                m = m < 10 ? "0" + m : m;
                var s = parseInt(times % 60);
                if (s > 0) {
                    s = s < 10 ? "0" + s : s;
                    _this.hour = h;
                    _this.minute = m;
                    _this.second = s;
                }
            }, 1000);
        },
        handlePopstate(event) {
            // 右滑返回事件触发时的处理逻辑
            console.log('右滑返回');
            window.location.reload()
            // 执行其他操作...
        }
    },
};
</script>
<style  lang="scss" scoped>
.resultBox {
    background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/pay/bg.png") no-repeat;
    background-size: 100% 100%;
    height: 100vh;

    .headerBox {
        text-align: center;
        padding-top: 25px;

        &_img {
            height: 55px;
            line-height:55px;
            margin: 0 23px;
            background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/turn/mz_bg.png") no-repeat;
            background-size: 100% 100%;
            font-size: 25px;
            font-weight: 500;
            color: #ffffff;
        }

        &_title {
            // margin: 20rem 0 20rem 232px;
            margin: 10px 100px;
            font-size: 16px;
            font-weight: 500;
            color: #ffffe5;
            // width: 70%;
            text-align: center;
            // padding: 0 150px;

            .txt {
                margin-bottom: 20px;
                text-align: center;
                flex: 1;
                width: 280px;
                color: #FFED51;
                &_left {
                    display: inline-block;
                    // flex: 1;
                    width: 28%;
                    text-align: justify;
                    text-align-last: justify;
                    text-justify: distribute-all-lines;
                    opacity: 0.7;
                }

                &_right {
                    flex: 1;
                    // width: 250px;
                    text-align: left;
                }

                &_right:before {
                    content: "：";
                }
            }
        }

        .desc {
            width: 300px;
            margin:10px auto 0;
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            color: #ae6d34;
            .list {
                // width: 140px;
                height: 80px;
                text-align: center;
                transition: all linear 2s;
                opacity: 0;
                background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/turn/show.png") no-repeat;
                background-size: 100% 100%;
                padding: 30px 25px;
                color: #573115;
                font-size: 14px;
                div {
                    margin-bottom: 14px;
                }
                .first {
                    color: #573115;
                    font-weight: bold;
                }

                .second {
                    // margin-bottom: 16px;
                }
            }
        }
    }



    .info {
        height: 400px;
        background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/turn/jc.png") no-repeat;
        background-size: 100% 100%;
        margin: 5px 12px 14px;
        padding: 21px 21px 0;
        position: relative;

        .tab {
            display: flex;
            justify-content: space-around;
            font-size: 14px;
            padding-bottom: 9px;
            // border-bottom: 1px solid rgba(152, 124, 103, 0.5);
            margin-bottom: 11px;

            &-list {
                width: 94px;
                height: 26px;
                line-height: 26px;
                text-align: center;
                color: #4b84a2;

                &.active {
                    background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/turn/selected.png") no-repeat;
                    background-size: 100% 100%;
                    font-weight: 500;
                    color: #fff;
                    text-align: center;
                }
            }
        }

        .content {
            color: #573115;
            font-size: 16px;
            position: absolute;
            width: calc(100% - 17px * 2);

            &-list {
                div {
                    margin-bottom: 11px;
                }

                .bar {
                    margin-bottom: 11px;
                    height: 8px;
                    background: linear-gradient(268deg, #D73E41, #A61B1D);
                    border-radius: 4px;

                    &-list {
                        width: 0;
                        height: 5px;
                        display: block;
                        // background: linear-gradient(-89deg, #A2181B, #E6C68E);
                        background: #A2181B;
                        border-radius: 8px;
                    }
                }
            }
        }
        .line{
            height: 1px;
            width: 100%;
            background:  #8B6F50;
            opacity: 0.28;
            margin-bottom: 20px;
            position: relative;
            img{
                position: absolute;
                top: -9px;
                left: 50%;
                transform: translate(-50%,0);

            }
        }
    }

    .animation {
        width: 100%;
        height: 100vh;
        position: absolute;
        left: 0;
        // top: 380px;
        top: 0;
        z-index: 999;
        background: rgba(0, 0, 0, 0.4);

        .outer {
            width: 300px;
            height: 300px;
            background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/turn/outside.png") no-repeat;
            background-size: contain;
            top: 291px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
            position: absolute;
        }

        .center {
            width: 230px;
            height: 230px;
            background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/turn/center.png") no-repeat;
            background-size: contain;
            top: 326px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;
            position: absolute;
        }

        .inner {
            width: 159px;
            height: 159px;
            background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/turn/inside.png") no-repeat;
            background-size: contain;
            top: 362px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 3;
            position: absolute;
        }

        .core {
            width: 83px;
            height: 83px;
            background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/turn/core.png") no-repeat;
            background-size: contain;
            top: 400px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 4;
            position: absolute;
        }

        .light {
            width: 30px;
            height: 115px;
            background: url("https://qiniu.zhiyunys.com/yanpitiao/sacc/turn/light.png") no-repeat;
            background-size: contain;
            top: 340px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 5;
            position: absolute;
        }
    }
}
</style>